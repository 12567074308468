// Utils
import metrics from "../metrics";
import { getPropertiesForPlanId, getCartProductsForIterable } from "./helpers";
import { getProductPromotionAttributes } from "./promotion";
import { getCookie } from "../cookies";

// Store
import { getStore } from "../../store/createStore";
import cartSelectors from "../../store/cart/selectors";
import cartProductSelectors from "../../store/cart-product/selectors";
import promotionSelectors from "../../store/promotion/selectors";
import { productOfferForId } from "../../store/product-offer/selectors";

export function trackCheckoutStarted() {
  const state = getStore().getState();

  const { code } = state.pendingCode;

  const cart = cartSelectors.activeCart(state);
  const productQuantity = cartProductSelectors.activeCartProductQuantity(state);
  const appliedProductPromotionProduct =
    promotionSelectors.appliedProductPromotionProduct(state);

  let properties = {
    cart_id: _getCartId(state),
    products: _getCartProducts(state),
    coupon: code,
    num_products: productQuantity,
    total: cart.total / 100,
    value: cart.total / 100,
    revenue: (cart.total - cart.totalTax) / 100,
    subtotal: cart.subtotal / 100,
    currency: cart.currency.toUpperCase(),
    tax: cart.totalTax / 100,
    discount: cart.discountAmount / 100,
    shipping: cart.shippingAmount / 100,
  };

  if (appliedProductPromotionProduct) {
    properties["product_promotion"] = getProductPromotionAttributes(
      appliedProductPromotionProduct.sku,
    );
  }

  metrics.track("Checkout Started", properties, {
    addEmail: true,
  });
}

export function trackOrderCompleted(orderNumber, cart, cartProducts) {
  const properties = {
    order_id: orderNumber,
    total: cart.total / 100,
    revenue: (cart.total - cart.total_tax) / 100,
    subtotal: cart.subtotal / 100,
    currency: cart.currency.toUpperCase(),
    tax: cart.total_tax / 100,
    discount: cart.discount_amount / 100,
    coupon: cart.discount_code,
    shipping: cart.shipping_amount / 100,
  };

  const productProperties = cartProducts.map((product) => {
    const { quantity, planId, productOfferId } = product;
    if (planId) {
      return getPropertiesForPlanId(planId, quantity);
    } else if (productOfferId) {
      const productOffer = productOfferForId(
        getStore().getState(),
        productOfferId,
      );
      return productOffer
        .initialPlanIds(getStore().getState())
        .map((planId) => {
          const properties = {
            ...getPropertiesForPlanId(planId, quantity),
            product_offer_id: productOfferId,
            product_offer_name: productOffer.name,
          };
          return properties;
        });
    }
    return {};
  });
  properties.products = productProperties.flat();

  const iterableCampaignId = getCookie("iterableEmailCampaignId");
  if (iterableCampaignId) {
    properties.campaignId = Number(iterableCampaignId);
    properties.templateId = Number(getCookie("iterableTemplateId"));
    properties.messageId = getCookie("iterableMessageId");
  }

  if (getCookie("tiktokEventId")) {
    properties.ttei = new Date().getTime() + "" + Math.random();
  }

  if (getCookie("facebookEventId")) {
    properties.fbp = getCookie("_fbp");
    properties.fbc = getCookie("_fbc");
  }

  metrics.track("Order Completed", properties, {
    addEmail: true,
  });
}

export function trackSubscribe(subscription_id, cart, cartProducts) {
  const properties = {
    subscription_id: subscription_id,
    currency: cart.currency.toUpperCase(),
  };

  // recurring product total from cartProducts where billingType === "recurring"
  const productProperties = cartProducts.map((product) => {
    const { quantity, planId, productOfferId } = product;
    if (planId) {
      return getPropertiesForPlanId(planId, quantity);
    } else if (productOfferId) {
      const productOffer = productOfferForId(
        getStore().getState(),
        productOfferId,
      );
      return productOffer
        .initialPlanIds(getStore().getState())
        .map((planId) => {
          const properties = {
            ...getPropertiesForPlanId(planId, quantity),
            product_offer_id: productOfferId,
            product_offer_name: productOffer.name,
          };
          return properties;
        });
    }
    return {};
  });

  const recurringProductTotal = productProperties
    .filter((product) => product.billing_type === "recurring")
    .reduce((total, product) => total + product.price * product.quantity, 0);

  properties.value = recurringProductTotal;

  if (getCookie("facebookEventId")) {
    properties.fbp = getCookie("_fbp");
    properties.fbc = getCookie("_fbc");
  }

  metrics.track("Subscribe", properties, {
    addEmail: true,
  });
}

export function trackProductAdded(planId, additionalProperties) {
  const state = getStore().getState();

  const { code } = state.pendingCode;

  const productProperties = getPropertiesForPlanId(planId);

  let products = [];
  if (Object.keys(productProperties).length > 0) {
    products = [{ ...productProperties }];
    products[0].id = products[0].product_id;
    delete products[0].product_id;
  }

  let properties = {
    cart_id: _getCartId(state),
    products,
    coupon: code,
  };

  properties = { ...properties, ...productProperties, ...additionalProperties };

  const iterableProducts = getCartProductsForIterable(state);

  const iterableProperties = { ...properties };
  iterableProperties.products = iterableProducts;

  metrics.track("Product Added", properties, {
    addTraitsToContext: true,
    addEmail: true,
    addFacebookCookies: true,
    addTikTokEventId: true,
    segmentOptions: {
      integrations: {
        Iterable: false,
      },
    },
  });

  metrics.track("Product Added", iterableProperties, {
    addTraitsToContext: true,
    addEmail: true,
    segmentOptions: {
      integrations: {
        All: false,
        Iterable: true,
      },
    },
  });
}

export function trackProductRemoved(planId, additionalProperties) {
  const state = getStore().getState();

  const { code } = state.pendingCode;

  let properties = {
    cart_id: _getCartId(state),
    coupon: code,
  };

  const productProperties = getPropertiesForPlanId(planId);
  properties = { ...properties, ...productProperties, ...additionalProperties };

  const iterableProducts = getCartProductsForIterable(state);

  const iterableProperties = { ...properties };
  iterableProperties.products = iterableProducts;

  metrics.track("Product Removed", properties, {
    addTraitsToContext: true,
    addEmail: true,
    addFacebookCookies: true,
    segmentOptions: {
      integrations: {
        Iterable: false,
      },
    },
  });

  metrics.track("Product Removed", iterableProperties, {
    addTraitsToContext: true,
    addEmail: true,
    segmentOptions: {
      integrations: {
        All: false,
        Iterable: true,
      },
    },
  });
}

// Cart Events Only

export function trackCheckoutCTAClicked() {
  const state = getStore().getState();

  const { code } = state.pendingCode;

  return metrics.track("Checkout CTA Clicked", {
    cart_id: _getCartId(state),
    products: _getCartProducts(state),
    coupon: code,
  });
}

export function trackCartViewed(eventName = "Cart Viewed") {
  const state = getStore().getState();

  const { code } = state.pendingCode;

  const productQuantity = cartProductSelectors.activeCartProductQuantity(state);
  const appliedProductPromotionProduct =
    promotionSelectors.appliedProductPromotionProduct(state);

  let properties = {
    cart_id: _getCartId(state),
    products: _getCartProducts(state),
    coupon: code,
    num_products: productQuantity,
  };

  if (appliedProductPromotionProduct) {
    properties["product_promotion"] = getProductPromotionAttributes(
      appliedProductPromotionProduct.sku,
    );
  }

  metrics.track(eventName, properties, {
    addEmail: true,
  });
}

// Upsell in cart events

export function trackUpsell(skus) {
  const state = getStore().getState();

  return metrics.track("product recommendations set", {
    product_in_cart: _getCartProducts(state),
    recommendations_returned: skus,
  });
}

// Helpers

export function _getCartProducts(state) {
  return cartProductSelectors
    .activeCartProducts(state)
    .flatMap((cartProduct) => {
      const { quantity, planId, productOfferId } = cartProduct;

      if (productOfferId) {
        const productOffer = productOfferForId(state, productOfferId);
        return productOffer.initialPlanIds(state).map((planId) => ({
          ...getPropertiesForPlanId(planId, quantity),
          product_offer_id: productOfferId,
          product_offer_name: productOffer.name,
        }));
      }

      return [getPropertiesForPlanId(planId, quantity)];
    });
}

function _getCartId(state) {
  const activeCart = cartSelectors.activeCart(state);
  return activeCart ? activeCart.id : null;
}
