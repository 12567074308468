import { Component } from "react";
import styled from "styled-components";
import bindClassNames from "classnames/bind";

// Utils
import { Color, Font, Opacity, rem, responsive, rgba } from "../../utils/style";
import NameMap from "../../utils/nameMap";
import { getBundleCartLimit } from "../../utils/bundle";
import { getProductForContentfulId } from "../../utils/planToProduct";
import { Icons } from "../../utils/react-svg";

// Redux
import { connect } from "react-redux";
import {
  removeProductFromCart,
  updateCartProductQuantity,
} from "../../store/cart/actions";
import planSelectors from "../../store/plan/selectors";

// Components
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import RemoveItemModal from "./RemoveItemModal";
import QuantityPicker from "../global/QuantityPicker";
import Currency from "../Currency";
import MagicLink from "../MagicLink";
import Text from "../Text";
import { productOfferForId } from "../../store/product-offer/selectors";
import { getStore } from "../../store/createStore";
import StrikeText from "../global/StrikeText";
import productSelectors from "../../store/product/selectors";

// Styled Elements
export const CartItemContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${Color.white};
  padding: 16px;

  &::before {
    content: "";
    position: absolute;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-top: 1px solid ${Color.fadedGrey};
  }

  &.simplified {
    padding: 24px 0;

    &::before {
      right: 0;
      bottom: 0;
      left: 0;
      border-top: 1px solid ${Color.veryFadedGrey};
    }
  }

  ${responsive.md`
    padding: 24px;

    &::before {
      right: 24px;
      left: 24px;
    }
  `};

  &:last-child {
    :not(.simplified) {
      border-bottom: none;

      margin-bottom: 0;

      &::before {
        display: none;
      }
    }
  }
`;

export const SectionWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: -7px;

  &.simplified {
    margin-top: 0;
  }
`;

export const ImageAndSectionContainer = styled.div`
  display: flex;
`;

// Remove a11y focus from image link since title link is immediately adjacent (duplicate)
const ImageWrapper = styled(MagicLink).attrs({
  tabIndex: -1,
  "aria-hidden": "true",
})`
  position: relative;
  width: 56px;
  height: 56px;
  margin-right: 12px;

  ${responsive.md`
    width: 120px;
    min-width: 120px;
    height: 120px;
    margin-right: 24px;
  `}
  &.simplified {
    width: 108px;
    min-width: 108px;
    height: 108px;
    margin-right: 16px;
  }
`;

export const SubscriptionInfo = styled.p`
  color: ${rgba(Color.ritualBlue, Opacity.light)};
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 22px;
  margin-bottom: 0;

  ${responsive.md`
    font-size: 14px;
    line-height: 24px;
  `}
  .savings {
    margin-left: auto;
    color: var(--Solid-Semantic-SuccessGreen, #4c840d);
    float: right;
  }
`;

const RemoveButton = styled.button`
  background: transparent;
  border: none;
  border-bottom: 2px solid ${Color.ritualBlue};
  padding: 0;
  color: ${Color.ritualBlue};
  font-weight: 500;
  font-size: ${rem(12)};
  line-height: ${rem(18)};

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  &:hover {
    opacity: 1;
  }

  ${responsive.md`
    font-size: ${rem(14)};
    line-height: ${rem(24)};
  `}
  &.simplified {
    border: none;
    opacity: 0.24;
    transition: 0.2s ease;

    &:hover {
      opacity: 0.6;
    }
  }
`;

const Price = styled.p`
  color: ${Color.ritualBlue};
  font-weight: 300;
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  margin: 0;

  ${responsive.md`
    font-size: ${rem(14)};
    line-height: ${rem(24)};
  `};
`;

export const ProductTitleDefault = styled.p`
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  font-weight: 500;
  margin: 0;
  padding-bottom: 8px !important;

  em {
    ${Font.dutch};
  }

  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(26)};

    padding-bottom: 16px !important;
  `}
`;

export const ProductTitleSimplified = styled.p`
  ${Font.dutch};
  font-style: italic;
  padding-bottom: 0 !important;
  font-size: ${rem(12)};
  line-height: ${rem(18)};

  em {
    ${Font.circular};
    font-style: normal;
    font-size: ${rem(14)};
  }

  &.product-offer-title {
    padding-bottom: 12px !important;
  }
`;

export const CartItemSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0;
  padding: 0;

  p {
    margin: 0;
    padding: 0;
  }
`;

export const CartItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  margin-top: auto;
  padding: 0;

  p {
    margin: 0;
    padding: 0;
    font-size: ${rem(12)};
    line-height: ${rem(20)};
  }
`;

export const ItemActions = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-left: auto;
  padding: 0;
  align-items: flex-end;

  p {
    margin: 0;
    padding: 0;
  }
`;

const QuantityWrapper = styled.div`
  margin-top: auto;
`;

const LastSection = styled(CartItemSection)`
  margin-top: 12px;

  ${responsive.md`
    margin-top: 16px;
  `};

  ${responsive.lg`
    margin-top: 9px;
  `};
`;

function getProductUrl(contentfulProduct) {
  const { contentfulId } = contentfulProduct;
  const { urlSlug } = getProductForContentfulId(contentfulId);

  return `/products/${urlSlug}`;
}

function getTitle(contentfulProduct, productOffer, simplified) {
  if (productOffer) {
    const state = getStore().getState();
    let names = "";
    if (simplified) {
      names = productOffer
        .productOfferPlans(state)
        .map((productOfferPlan) => {
          const plan = productOfferPlan.initialPlan(state);
          return productSelectors.productForId(state, { id: plan.productId })
            .name;
        })
        .join(", ");
    }

    return `<em>${productOffer.name}</em><br />${names}`;
  }

  const { name } = contentfulProduct;
  const styledName = NameMap(name);
  let title = styledName.html;

  if (simplified) {
    title = `${contentfulProduct.productSubhead}<br /><em>${contentfulProduct.summary}</em>`;
  }

  return title;
}

function getCartImage(contentfulProduct, productOffer) {
  const state = getStore().getState();

  if (productOffer) {
    return productOffer.contentfulProductBundle(state).shopImages?.[0];
  }

  const { cartImage } = contentfulProduct;
  return cartImage;
}

function getDescription(plan, productOffer) {
  if (productOffer) {
    return "Monthly";
  }

  return plan && plan.description;
}

export function getVariantDescription(plan, productOffer) {
  if (productOffer) {
    const productQuantity = productOffer.productQuantity(getStore().getState());

    return `${productQuantity}-Item Bundle`;
  }

  return plan && plan.variantDescription;
}

function getPrice(cartProduct, productOffer, simplified) {
  const { productPrice } = cartProduct;
  const round = true;
  const price = (productPrice / 100).toFixed(2);
  const currentPrice = <Currency value={price} round={round} />;

  let futurePriceMarkup;
  if (productOffer) {
    const futurePrice = productOffer.futureAmount(getStore().getState());
    const futurePriceAdjusted = (futurePrice / 100).toFixed(2);

    const savings = Math.floor(
      ((futurePriceAdjusted - price) * 100) / 100,
    ).toFixed(0);

    futurePriceMarkup = (
      <>
        <StrikeText>
          <Currency value={futurePriceAdjusted} round={round} />
        </StrikeText>
        {simplified && (
          <span className={"savings"}>
            <Currency value={savings} round={true} /> savings
          </span>
        )}
      </>
    );
  }

  return (
    <>
      {currentPrice}
      {futurePriceMarkup}
    </>
  );
}

function getTitleClassName(classNames, productOffer) {
  if (productOffer) {
    return classNames + " product-offer-title";
  }

  return classNames;
}

export const CartItemComponent = class CartItem extends Component {
  constructor() {
    super();
    this.state = {
      isModalOpen: false,
    };
  }

  renderImage(cartImage, productUrl, classNames) {
    return (
      <ImageWrapper className={classNames} to={productUrl}>
        {cartImage && (
          <GatsbyImage
            image={getImage(cartImage)}
            loading="eager"
            alt={cartImage.description}
            aria_label={cartImage.description}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              zIndex: "0",
              userSelect: "none",
              userDrag: "none",
              pointerEvents: "none",
              touchCallout: "none",
            }}
          />
        )}
      </ImageWrapper>
    );
  }

  renderRemove(isProcessing) {
    const { simplified } = this.props;
    return (
      <RemoveButton
        className={bindClassNames(simplified && "simplified")}
        disabled={isProcessing}
        onClick={this.toggleVisibility.bind(this)}
      >
        {simplified ? (
          <Icons.Trash />
        ) : (
          <Text id="cart.page.remove-item" defaultMessage="Remove" />
        )}
      </RemoveButton>
    );
  }

  renderQuantity() {
    const { cartProduct, cartQuantity, isProcessing, simplified } = this.props;
    return (
      <QuantityPicker
        itemId={cartProduct.id}
        itemQuantity={cartProduct.quantity}
        totalQuantity={cartQuantity}
        maxQuantity={getBundleCartLimit()}
        onQuantityChange={this.onQuantityChange.bind(this)}
        disabled={isProcessing}
        rounded={simplified}
      />
    );
  }

  onQuantityChange(e) {
    const { cartProduct } = this.props;
    const quantity = e.target.value;

    this.props.dispatchUpdateCartProductQuantity(cartProduct.planId, quantity);
  }

  toggleVisibility(e) {
    e && e.preventDefault();
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }

  removeItem(cartProduct, e) {
    e.preventDefault();
    if (this.props.dispatchRemoveProductFromCart) {
      const productData = {
        planId: cartProduct.planId,
        productOfferId: cartProduct.productOfferId,
      };
      this.props.dispatchRemoveProductFromCart(productData, true);
    }
    this.setState({
      isModalOpen: false,
    });
  }

  render() {
    const {
      cartProduct,
      contentfulProduct,
      isProcessing,
      plan,
      productOffer,
      className,
      simplified,
      isFlyoutContext,
    } = this.props;
    const { isModalOpen } = this.state;

    if (!plan && !productOffer) return null;

    const cartImage = getCartImage(contentfulProduct, productOffer);

    const title = getTitle(contentfulProduct, productOffer, simplified);

    const classNames = bindClassNames(
      className,
      simplified && "simplified",
      isFlyoutContext && "flyout",
    );
    const ProductTitle = simplified
      ? ProductTitleSimplified
      : ProductTitleDefault;

    const productUrl = !productOffer && getProductUrl(contentfulProduct);

    return (
      <>
        <CartItemContainer className={classNames}>
          <ImageAndSectionContainer>
            {this.renderImage(cartImage, productUrl, classNames)}
            <SectionWrapper className={classNames}>
              <CartItemSection>
                <MagicLink className={classNames} to={productUrl}>
                  <ProductTitle
                    className={getTitleClassName(classNames, productOffer)}
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                </MagicLink>
              </CartItemSection>
              {simplified ? (
                <>
                  <CartItemInfo className={classNames}>
                    <SubscriptionInfo>
                      {getVariantDescription(plan, productOffer)}
                    </SubscriptionInfo>
                    <SubscriptionInfo>
                      {getDescription(plan, productOffer)}
                    </SubscriptionInfo>
                    <SubscriptionInfo>
                      {getPrice(cartProduct, productOffer, simplified)}
                    </SubscriptionInfo>
                  </CartItemInfo>
                </>
              ) : (
                <>
                  <CartItemSection>
                    <SubscriptionInfo>
                      {getVariantDescription(plan, productOffer)}
                    </SubscriptionInfo>
                    <Price>
                      {getPrice(cartProduct, productOffer, simplified)}
                    </Price>
                  </CartItemSection>
                  <CartItemSection>
                    <SubscriptionInfo>
                      {plan && plan.variantContents}
                    </SubscriptionInfo>
                    <SubscriptionInfo>
                      {getDescription(plan, productOffer)}
                    </SubscriptionInfo>
                  </CartItemSection>
                  <LastSection>
                    {this.renderRemove(isProcessing)}
                    {plan && this.renderQuantity()}
                  </LastSection>
                </>
              )}
            </SectionWrapper>
            {simplified && (
              <ItemActions>
                {this.renderRemove(isProcessing)}
                {plan && (
                  <QuantityWrapper>{this.renderQuantity()}</QuantityWrapper>
                )}
              </ItemActions>
            )}
          </ImageAndSectionContainer>
        </CartItemContainer>

        <RemoveItemModal
          isOpen={isModalOpen}
          onRequestClose={this.toggleVisibility.bind(this)}
          onRemove={this.removeItem.bind(this, cartProduct)}
        />
      </>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    plan: planSelectors.planForId(state, {
      id: ownProps.cartProduct.planId,
    }),
    productOffer: productOfferForId(state, ownProps.cartProduct.productOfferId),
  };
};

export default connect(mapStateToProps, {
  dispatchRemoveProductFromCart: removeProductFromCart,
  dispatchUpdateCartProductQuantity: updateCartProductQuantity,
})(CartItemComponent);
