import styled from "styled-components";

// Components
import MagicFade, { FadeStyles } from "../MagicFade";
import MagicLink from "../MagicLink";
import Text from "../Text";
import CartItem from "./CartItem";
import ProductPromotionCartItem from "./ProductPromotionCartItem";

// Utils
import { getProductSkuForPlanId } from "../../utils/planToProduct";
import { Color, Opacity } from "../../utils/style";

const ProductsWrapper = styled.div`
  ${FadeStyles}
`;

const EmptyCart = styled.div`
  width: 100%;
  text-align: center;
  padding: 30px 0px;
`;

const LinkContainer = styled.div`
  margin-top: 30px;
`;

const ShopLink = styled(MagicLink)`
  margin-top: 30px;
  border-bottom: 2px solid ${Color.ritualBlue};
  text-decoration: none;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;

  &:hover {
    opacity: ${Opacity.light};
  }
`;

function CartProduct({
  cartProduct,
  contentfulProducts,
  isProcessing,
  cartQuantity,
  isFlyout,
}) {
  const productSku = getProductSkuForPlanId(cartProduct.planId);
  const contentfulProduct = contentfulProducts.find(
    (product) => productSku === product.sku,
  );

  return (
    <CartItem
      key={cartProduct.key}
      className={"simplified"}
      cartProduct={cartProduct}
      contentfulProduct={contentfulProduct}
      isProcessing={isProcessing}
      cartQuantity={cartQuantity}
      simplified={true}
      isFlyoutContext={isFlyout}
    />
  );
}

export default function CartItemList({
  cartProducts,
  contentfulProducts,
  isProcessing,
  cartQuantity,
  isFlyout,
}) {
  return (
    <ProductsWrapper>
      <MagicFade transitionKey={!!cartQuantity}>
        <>
          {!cartQuantity && (
            <EmptyCart>
              <Text
                id="cart.empty.message"
                defaultMessage="Your cart is empty."
              />
              <br />
              <Text
                id="cart.empty.message-second"
                defaultMessage="Fill it with something good."
              />
              <LinkContainer>
                <ShopLink to="/shop/all">
                  <Text
                    id="home.featured-products.link"
                    defaultMessage="Shop All"
                  />
                </ShopLink>
              </LinkContainer>
            </EmptyCart>
          )}
          {cartProducts.map((cartProduct, i) => (
            <CartProduct
              key={i}
              cartProduct={cartProduct}
              contentfulProducts={contentfulProducts}
              isProcessing={isProcessing}
              cartQuantity={cartQuantity}
              isFlyoutContext={isFlyout}
            />
          ))}

          <ProductPromotionCartItem isFlyout={isFlyout} simplified={true} />
        </>
      </MagicFade>
    </ProductsWrapper>
  );
}
