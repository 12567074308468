import { createSelector } from "reselect";
import siteStore from "../../services/siteStore";

const _byId = (state) => (state.productOffers ? state.productOffers.byId : {});
const _allIds = (state) =>
  state.productOffers ? state.productOffers.allIds : [];

export const allProductOffers = createSelector(_byId, _allIds, (byId, allIds) =>
  allIds.map((id) => byId[id]),
);

export const allProductOffersByCurrentStore = createSelector(
  [allProductOffers, (state) => state],
  (productOffers, state) =>
    productOffers.filter((productOffer) =>
      productOffer
        .productOfferPlans(state)
        .some(
          (productOfferPlan) =>
            productOfferPlan.initialPlan(state)?.storeId === siteStore.storeId,
        ),
    ),
);

export const productOfferForId = createSelector(
  allProductOffers,
  (_, id) => id,
  (allProductOffers, id) => allProductOffers.find((productOffer) => productOffer.id === id),
);
