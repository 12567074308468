import { Component } from "react";
import throttle from "lodash/throttle";

import metrics from "../../utils/metrics";

export default class ScrollDepth extends Component {
  constructor(props) {
    super(props);

    this.scrollPositions = null;
    this.scrollCache = [];
    this.segmentScroll = this.segmentScroll.bind(this);
    this.throttledSegmentScroll = throttle(this.segmentScroll, 200);
    this.timeoutHandler = null;
  }

  componentDidMount() {
    // Trigger init on mount
    this.initScrollDepth();
  }

  componentWillUnmount() {
    this.clearTimeoutHandler();
    window.removeEventListener("scroll", this.throttledSegmentScroll);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pathname !== this.props.pathname) {
      // Trigger init when page changes
      this.initScrollDepth();
    }
  }

  initScrollDepth() {
    this.clearTimeoutHandler();
    // Timeout handles weirdness with pages that have ScrollMagic
    this.timeoutHandler = setTimeout(() => {
      window.removeEventListener("scroll", this.throttledSegmentScroll);

      // Pass document total height
      this.setScrollPositions(document.documentElement.scrollHeight);

      if (this.scrollCache.length) {
        this.scrollCache = [];
      }
      window.addEventListener("scroll", this.throttledSegmentScroll);
    }, 300);
  }

  setScrollPositions(height) {
    // Gets positions on page where each trigger should land
    this.scrollPositions = {
      "25%": parseInt(height * 0.25, 10),
      "50%": parseInt(height * 0.5, 10),
      "75%": parseInt(height * 0.75, 10),
      // Cushion to trigger 100% event in iOS
      "100%": height - 5,
    };
  }

  segmentScroll() {
    // Scroll position + window height
    const scrollPosition = window.pageYOffset + window.innerHeight;
    const { scrollCache, scrollPositions } = this;

    // Loop through the keys (25%, 50%, 75%, 100%)
    for (let key in scrollPositions) {
      const val = scrollPositions[key];

      // If key doesn't exist in cache and scroll position is > key value
      if (!scrollCache.includes(key) && scrollPosition >= val) {
        metrics.track("Scroll Depth Triggered", {
          depth: key,
        });

        // Add key to cache so we don't send duplicate events
        this.scrollCache.push(key);
      }
    }
  }

  clearTimeoutHandler() {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
    }
  }

  render() {
    return null;
  }
}
