import { useRef, useState } from "react";
import styled from "styled-components";
import { Color } from "../../utils/style";
import useIsInView from "../../hooks/useIsInView";

const Container = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${Color.ritualGrey};
  border-radius: 10px;
  position: relative;
  overflow: hidden;
`;

const Bar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: ${(p) => `${100 - (p.percent || 0)}%`};
  background-color: ${Color.ritualYellow};
  border-radius: 10px;
  transition: right 2s ease-in-out;
`;

export default function ProgressBar({ animate = true, percent }) {
  const [isAnimated, setIsAnimated] = useState(!animate);

  const ref = useRef();
  const isInView = useIsInView(ref);

  if (!isAnimated && isInView) {
    setIsAnimated(true);
  }

  return (
    <Container ref={ref}>
      <Bar percent={isAnimated ? percent : 0} />
    </Container>
  );
}
