// Services
import launchDarkly from "../services/launchDarkly";
import intl from "../services/intl";

//Utils
import { reload } from "./navigate";
import { getCookie, removeCookie } from "./cookies";
import metrics from "./metrics";
import { analytics } from "./analytics";

// Store
import { getStore } from "../store/createStore";
import { fetchUser } from "../store/user/actions";
import cartSelectors from "../store/cart/selectors";
import userSelectors from "../store/user/selectors";

// Authenticte
import { SET_COOKIE, updateAuthenticationCookie } from "./authenticate";
import fetch, { setAccessToken } from "./fetch";

// Helpers
import { safeParse, safeStringify, IsStorageSupported } from "./helpers";
import User from "../store/user/model";

let authAndFetchUserPromiseResolve;
const authAndFetchUserPromise = new Promise(
  (resolve) => (authAndFetchUserPromiseResolve = resolve),
);

export function readAuthenticationCookie() {
  let cookie = getCookie("ritual_auth-session");
  if (!cookie) return {};

  let authCookie = safeParse(cookie);
  if (!authCookie) return {};

  const { access_token, refresh_token, expires_at, token_type, scope } =
    authCookie.authenticated;
  const expiration_date = new Date(expires_at);

  return {
    token_type,
    access_token,
    refresh_token,
    expires_at,
    expiration_date,
    scope,
  };
}

function _requestRefreshToken(refreshToken) {
  return fetch("oauth/token", {
    method: "POST",
    credentials: "include",
    body: `refresh_token=${refreshToken}&grant_type=refresh_token`,
    headers: {
      Accept: "application/json", // Guarantees returns JSON
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

async function _refreshToken(cookie) {
  const data = await _requestRefreshToken(cookie.refresh_token);

  if (SET_COOKIE) {
    updateAuthenticationCookie(data);
  }
}

function _fetchAndStoreUser(accessToken) {
  return getStore()
    .dispatch(fetchUser(accessToken))
    .then((data) => {
      const user = data && data.data;
      const { id } = user;
      _storeCurrentUser(id);

      const userModel = new User();
      userModel.deserialize(user);
      return userModel;
    })
    .catch((error) => {
      console.error(error);

      // If the user is not authenticated we should clear the cookies and
      // refresh the page.
      if (error.status === 401) {
        clearCurrentUser();
        reload();
      }
    });
}

export function getTimeZone(options = {}) {
  if (typeof Intl !== "undefined" && Intl.DateTimeFormat) {
    const dateFormat = new Intl.DateTimeFormat("default", options);
    const { timeZone } = dateFormat.resolvedOptions();
    return timeZone;
  }
}

export async function getCurrentUser() {
  return authAndFetchUserPromise;
}

export function identifyUser() {
  const state = getStore().getState();

  const user = userSelectors.activeUser(state);
  if (!user) return;

  const { id, email, firstName, lastName, status = "Pending", locale } = user;

  const segmentAttributes = {
    email,
    first_name: firstName,
    last_name: lastName,
    status,
    locale,
    country: intl.country,
    time_zone: getTimeZone(),
  };

  // Add the cart id to the segment identify call, if one exists.
  const activeCart = cartSelectors.activeCart(state);
  if (activeCart && activeCart.id) {
    segmentAttributes.cart_id = activeCart.id;
  }

  metrics.identify(id, segmentAttributes, {
    Intercom: { user_hash: user.intercomHash },
  });

  launchDarkly.identify();

  if (window._talkableq) {
    window._talkableq.push([
      "authenticate_customer",
      {
        email,
        first_name: firstName,
        last_name: lastName,
        customer_id: id,
      },
    ]);
  }

  if (!window.dataLayer) window.dataLayer = [];
  window.dataLayer.push({ userStatus: status });
}

export function getUserTraits() {
  const state = getStore().getState();
  const activeUser = userSelectors.activeUser(state);

  if (activeUser) {
    const { email, firstName, lastName } = activeUser;
    return { email, firstName, lastName };
  }

  const storedTraits = analytics.getStoredUserInfo().traits;
  const { email, first_name: firstName, last_name: lastName } = storedTraits;
  return { email, firstName, lastName };
}

function _storeCurrentUser(id) {
  if (!IsStorageSupported) return;

  let currentUser = safeStringify({
    id,
  });

  if (!currentUser) return;

  window.localStorage.setItem("currentUser", currentUser);
}

export function getStoredUser() {
  if (!IsStorageSupported) return null;

  const currentUser = window.localStorage.getItem("currentUser");

  // If there's a currentUser property in storage but it isn't an object with
  // an id, return null.
  const parsedUser = safeParse(currentUser);
  if (parsedUser && !parsedUser.id) return null;

  return parsedUser;
}

export function clearCurrentUser() {
  removeCookie("ritual_auth-session", {
    domain: process.env.GATSBY_COOKIE_DOMAIN,
  });

  if (!IsStorageSupported) return;

  return window.localStorage.removeItem("currentUser");
}

export function hasLoggedOutUser() {
  const cookie = readAuthenticationCookie();
  const storedUser = getStoredUser();

  const hasAuthCookie = cookie && cookie.access_token;
  return !!storedUser && !hasAuthCookie;
}

export async function authAndFetchUser() {
  let cookie = readAuthenticationCookie();

  if (!cookie || !cookie.access_token) {
    clearCurrentUser();
    authAndFetchUserPromiseResolve();
    return authAndFetchUserPromise;
  }

  const { expiration_date } = cookie;
  const isAccessTokenExpired = expiration_date <= new Date();

  // If Access Token is expired, refresh the token.
  if (isAccessTokenExpired) {
    try {
      await _refreshToken(cookie);
    } catch (e) {
      // If refreshing the token fails, clear the stored user and auth cookie.
      console.error(e);
      clearCurrentUser();
      return Promise.resolve();
    }
    cookie = readAuthenticationCookie();
  }

  setAccessToken(cookie.access_token);

  const user = await _fetchAndStoreUser(cookie.access_token);

  authAndFetchUserPromiseResolve(user);
  return user;
}
